.main__container {
    margin-top: 100px;
    margin-bottom: 50px;
}

@media (max-width: 576px) {
    .main__container {
        margin-top: 50px;
    }
}

.project__list {
    text-align: center;
    margin: 0;
    padding: 0;
    color: #CCD6F6;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    align-items: center;
}

.project__archive {
    text-align: center !important;
    font-family: 'Poppins', sans-serif;
}

.project__arcLink {
    text-decoration: none !important;
    color: #64FFDA;
}

.project__arcLink:hover {
    border-bottom: 1px solid #64FFDA;
    color: #64FFDA;
}