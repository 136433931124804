.social {
    margin-top: 10px;
    align-items: center;
}

.copyright {
    margin-top: 10px;
}

.social__title {
    color: #8892B0;
    font-family: 'Montserrat', sans-serif;

}

.wrapper .button{
    display: inline-block;
    height: 60px;
    width: 60px;
    float: left;
    margin: 0 5px;
    overflow: hidden;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
    transition: all 0.3s ease-out;
}

.wrapper .button .icon{
    display: inline-block;
    height: 60px;
    width: 60px;
    color: #CCD6F6;
    text-align: center;
    border-radius: 50px;
    box-sizing: border-box;
    line-height: 60px;
    transition: all 0.3s ease-out;
}
.wrapper .button .icon i{
    font-size: 25px;
    line-height: 60px;
    transition: all 0.3s ease-out;
}
.wrapper .button:hover .icon i {
    color: #64FFDA;
}


