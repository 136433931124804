/* !*Hide scrollbar for Chrome, Safari and Opera*!*/
::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    background-color: #0a192f;
}

::selection {
    /*color: #17BF63;*/
    background: #8892B0;
}

::-moz-selection {
    /*color: #17BF63;*/
    background: #17BF63;
}


/*!* width *!*/
/*::-webkit-scrollbar {*/
/*    width: 5px;*/
/*}*/

/*!* Track *!*/
/*::-webkit-scrollbar-track {*/
/*    background: #212529;*/
/*}*/

/*!* Handle *!*/
/*::-webkit-scrollbar-thumb {*/
/*    background: #17BF63;*/
/*}*/

/*!* Handle on hover *!*/
/*::-webkit-scrollbar-thumb:hover {*/
/*    background: #17BF63;*/
/*}*/