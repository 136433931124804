.footer {
    margin-bottom: 50px;
    margin-top: 30px;
    /*background-color: yellow;*/
}

.footer__title {
    color: #8892B0;
    font-family: 'Montserrat', sans-serif;
}

.footer__content p {
    line-height: 25px;
    font-family: 'Montserrat', sans-serif;
    color: #8892B0;
    margin: 0;
}

/*.footer__email {*/
/*    text-decoration: none;*/
/*    color: #212529;*/
/*}*/

/*.footer__email:hover {*/
/*    text-decoration: none;*/
/*    color: #17BF63;*/
/*}*/

.footer__contact {
    text-decoration: none !important;
    color: #CCD6F6;
}

.footer__contact:hover {
    text-decoration: none !important;
    color: #64FFDA !important;
}

/*Copyright text */
.copyright__text {
    color: #8892B0;
    font-family: 'Montserrat', sans-serif;
}