.navbar-nav > a,
nav > a {
    text-decoration: none;
    color: #CCD6F6 !important;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 1px;
    padding: 0;
    font-size: 14px;
    margin: 0;
}


.navbar-nav span {
    color: #64FFDA;
}

.logo {
    font-family: 'Chilanka', cursive;
    color: #64FFDA !important;
    /*color: #17BF63 !important;*/
}

@media (max-width: 576px) {

    .navigation {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .nav__resumeBtn {
        margin-left: 0 !important;
        margin-top: 5px !important;
    }
}

.navbar {
    padding: 0;
}


.navbar-nav > a:hover {
    /*color: #17BF63 !important;*/
    color: #64FFDA !important;
}

.navigation {
    margin-top: 50px;
    padding:0;
}

.navigation__active {
    /*border-bottom: 1px solid #64FFDA;*/
    background: rgba(100, 255, 218, 0.1);
    border-radius: 5px;

    /*border-top: 2px solid #64FFDA;*/
}

.nav__resumeBtn {
    padding: 7px 15px;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #64FFDA;
    font-family: 'Montserrat', sans-serif;
    background: transparent;
    letter-spacing: 1px;
    color: #64FFDA;
    outline: none;
    margin-left: 10px;
}

.nav__resumeBtn:hover {
    background: rgba(100, 255, 218, 0.1);
    outline: none;
}

.toggler__btn {
    color: #64FFDA !important;
}

.navbar-toggler {
    background-color: rgba(100, 255, 218, 0.1);
    border: 1px solid #64FFDA !important;
    outline: none !important;
}

.navbar-toggler i {
    color: #64FFDA;
}