.form__contents {
    padding-top: 10px;
    padding-left: 0;
}

.submitted__messages {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 95px;
    /*padding-bottom: 30px;*/
    box-sizing: border-box;
    /*background-color: red;*/
}

.inline-form{
    margin-top: 40px;
    box-sizing: border-box;
    position: relative;
}

.inline__form .input-data{
    height: 40px;
    width: 100%;
    position: relative;
}

.inline__form .input-data textarea {
    resize: none;
}

.inline__form .input-data input, textarea{
    height: 100%;
    width: 100%;
    border: none;
    font-size: 20px;
    color: #CCD6F6;
    outline: none;
    background: transparent;
    border-bottom: 2px solid #8892B0;
}

.input-data input:focus ~ label,
.input-data textarea:focus ~ label,
.input-data textarea:valid ~ label,
.input-data input:valid ~ label{
    transform: translateY(-30px);
    font-size: 20px;
    color: #64FFDA;
}

.inline__form .input-data label{
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 20px;
    color: #8892B0;
    pointer-events: none;
    transition: all 0.3s ease;
}
.input-data .underline{
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 0;
}
.input-data .underline:before{
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #64FFDA;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease;
}

.input-data input:focus ~ .underline:before,
.input-data textarea:focus ~ .underline:before,
.input-data textarea:valid ~ .underline:before,
.input-data input:valid ~ .underline:before {
    transform: scaleX(1);
}

/*Type writer animation: Input Boxes*/
.label__animation {
    white-space: nowrap;
    overflow: hidden;
    margin: 0 !important;
}

/* Animation */
.label__animation {
    animation: animated-text 1s steps(30,end) 0.5s 1 normal both;
}

/* text animation */

@keyframes animated-text{
    from{width: 0;}
    to{width: 472px;}
}

/* Message display animation: Displaying Messages */
.submitted__messages-animation1,
.submitted__messages-animation2{
    white-space: nowrap;
    overflow: hidden;
    margin: 0 !important;
    /*background-color: red;*/
}

.name__label,
.email__label {
    color: #64FFDA;
    font-weight: bold;
}

.info__edit:hover {
    color: #64FFDA;
    border-bottom: 1px solid #64FFDA;
    cursor: pointer;
}

.info__edit {
    border-bottom: 1px solid #64FFDA;
    font-size: 20px;
}

/*Buttons CSS*/

.next__button,
.submit__button{
    background: transparent;
    border: 1px solid #64FFDA;
    font-family: 'Montserrat', sans-serif;
    height: 35px;
    margin-right: 15px;
    width: 15%;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    outline: none !important;
    letter-spacing: 2px;
}

.next__button:hover,
.submit__button:hover{
    background: rgba(100, 255, 218, 0.1);
    color: #64FFDA;
    cursor: pointer;
    outline: none !important;
    transition: 0.2s ease-in;
}

.next__button span,
.submit__button span{
    font-size: 16px;
}

@media (max-width: 576px) {
    .next__button,
    .submit__button{
        height: 35px;
        width: 20%;
    }

    .next__button span,
    .submit__button span{
        font-size: 14px;
    }
}



