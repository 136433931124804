/*New card css*/
.card__left {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    vertical-align: center;
    /*background-color: red;*/
    padding: 30px 20px;
    box-sizing: border-box;
}

.card__right {
    display: flex;
    justify-content: center;
    align-content: center;
    /*background-color: pink;*/
    padding: 20px;
    box-sizing: border-box;
}

.card__wrapper {
    width: 90%;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
    height: auto;
    /*background: red;*/
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}

/*.overlay {*/
/*    width: 100%;*/
/*    border-radius: 5px;*/
/*    height: 100%;*/
/*    position: absolute;*/
/*    background: rgba(100, 255, 218, 0.3);*/
/*    transition: all 0.3s ease-in-out;*/
/*    z-index: 1;*/
/*}*/

.card__image {
    width: 100%;
    border-radius: 5px;
    height: 100%;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    transition: all 0.3s ease-out;

}

.card__image:hover {
    transform: scale(1.2);
    cursor: pointer;
}

/*.overlay:hover {*/
/*    cursor: pointer;*/
/*    background: none;*/
/*    transition: all 0.3s ease-in-out;*/
/*}*/


.project__description {
    background: #172A45;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
    color: #A8B2D1;
    margin: 20px 0;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    transition: all 0.3s ease-out;
}

.project__description:hover {
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -ms-transform: translateX(30px);
    -o-transform: translateX(30px);
    transform: translateX(30px);
    transition: all 0.3s ease-out;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0 0 50px rgba(0, 0, 0, 0.2);
}

.project__title span{
    color: #64FFDA;
}

.project__title h2{
    color: #CCD6F6;
    margin: 0;
    padding: 0;
}

.project__number  {
    color: rgba(100, 255, 218, 0.1);
    font-size: 100px;
    left: 0;
    top: 0;
    margin-left: 15px;
    font-family: 'Poppins', sans-serifs;
    letter-spacing: 10px;
    position: absolute;
    z-index: 1;
}

.project__tech {
    display: flex;
    justify-content: flex-start;
    /*background: yellow;*/
    padding: 0 !important;
    margin-bottom: 10px;
}

.project__tech li {
    list-style: none;
    display: inline-block;
    padding-right: 20px;
    color: #A8B2D1;
}

.card__container {
    padding: 0;
    margin-top: 20px;
}

.card__demoBtn {
    padding: 7px 15px;
    border-radius: 5px;
    margin-right: 10px;
    box-sizing: border-box;
    border: 1px solid #64FFDA;
    background: transparent;
    font-family: 'Poppins', sans-serif;
    color: #64FFDA;
    outline: none;
}

.card__demoBtn:hover {
    background: rgba(100, 255, 218, 0.1);
    outline: none;
}

.project__link {
    display: flex;
    /*align-content: center;*/
    vertical-align: center;
    /*background-color: red;*/
}

.project__github i {
    font-size: 25px;
    color: #CCD6F6;
    justify-content: center;
    align-self: center;
    margin-top: 8px;
}

.project__github i:hover {
    color: #64FFDA;
}

.project__github {
    margin-right: 20px !important;
}




